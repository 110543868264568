.container {
  /* max-width: 66.875em; */
  display: flex;
  margin-left: 10.3125em;
  margin-right: 10.3125em;
  align-items: center;
  /* margin-left: auto;
  margin-right: auto; */
  justify-content: space-between;
  margin-top: 2.5em;
}

.containerOtherPage {
  background: var(--gradientText);
  margin: 0;
  padding: 2.53em 0;
}
.containerMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 66.875em;
}

.logo {
  width: 13.0625em;

  cursor: pointer;
}

.navItem {
  color: var(--white);
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
}

.bookButton {
  position: relative;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 0.58em;
  padding: 0.625em 1.25em;
  cursor: pointer;
}

.bookButton:hover {
  box-shadow: 0.125em 0.125em 0.5em rgba(32, 32, 32, 0.25);
}

.bookButton p {
  margin: 0;
  background: var(--gradientText);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "open sans";
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
}

.bookButton:hover > p {
  background: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: black;
}

.navItems {
  display: flex;
  align-items: center;
  gap: 1.75em;
}
.navItemWrapper {
  position: relative;
}

.navItemUnderline {
  width: 50%;
  content: "";
  background-color: var(--white);
  height: 0.2em;
  left: 1em;
  bottom: 6px;
  position: absolute;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.navItem:hover + .navItemUnderline {
  visibility: visible;
  transform: scaleX(1.5);
}

@media (max-width: 768px) {
  .container {
    margin-left: 5.3125em;
    margin-right: 5.3125em;
  }

  .containerMain {
    margin-left: 5.3125em;
    margin-right: 5.3125em;
  }

  .logo {
    width: 12.837538733953076em;
    height: auto;
  }

  .navItem {
    font-size: 1.593625498007968rem;
  }

  .bookButton p {
    font-size: 1.239486498450642rem;
  }
  .navItems {
    gap: 4.5em;
  }
}

.mobileMenuWrapper {
  display: none;
}

@media (min-width: 1440px) {
  .container {
    max-width: 69.375em;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .containerMain {
    max-width: 69.375em;
  }

  .logo {
    width: 13.0625em;
  }

  .navItem {
    font-size: 1.125rem;
    font-weight: 500;
  }
  .bookButton {
    padding: 0.625em 1.25em;
  }
  .bookButton p {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .container {
    margin: 1.5em;
  }
  .containerMain {
    margin: 0;
  }

  .containerOtherPage {
    padding: 1.5em;
  }
  .navItems {
    display: none;
  }
  .mobileMenuWrapper {
    display: block;
  }

  .mobileMenuIcon {
    width: 1.25em;
  }

  .logo {
    width: 6.3125em;
    height: auto;
  }
}
