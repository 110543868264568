.containerWrapper {
  background: linear-gradient(180deg, #edf1ff 0%, rgba(243, 246, 255, 0) 100%);
}

.container {
  max-width: 66.875em;
  margin: auto;
  text-align: left;
  padding: 5em 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.heading1 {
  margin: 0;
  text-align: left;
}

.normalText {
  font-size: 1.2rem;
}

@media (max-width: 480px) {
  .container {
    padding: 2em 1.5em;
  }
  .normalText {
    font-size: 1rem;
    margin: 1em 0;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 2em 1.5em;
  }
  .normalText {
    font-size: 1rem;
    margin: 1em 0;
  }
}
