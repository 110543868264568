.container {
  background-color: var(--white);
  box-shadow: 0.125em 0.125em 0.5em rgba(32, 32, 32, 0.08);
  border-radius: 1em;
  max-width: 36.8125em;
  padding: 3.75em 5.9375em;
  margin: auto;
  position: relative;
  text-align: left;
  margin-top: 10vh;
}

.containerWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background: rgba(26, 26, 26, 0.36);
  display: none;
}

.containerWrapperActive {
  display: block;
}

.heading {
  color: var(--black);
  font-weight: 800;
  margin: 0;
  font-size: 1.5rem;
}

.closeIcon {
  position: absolute;
  top: 2.25em;
  right: 2.25em;
  width: 1.65em;
  cursor: pointer;
}

.closeIcon:hover {
  filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(0%)
    contrast(200%);
}

.demoForm {
  display: flex;
  gap: 1.25em;
  flex-direction: column;
  margin-top: 1.875em;
}

.formLabel {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 0.25em;
}

.formInput,
.formSelect {
  border: 0.0625em solid #cccccc;
  border-radius: 0.25em;
  width: 100%;
  height: 2.5em;
  font-size: 1rem;
  padding: 0.5em 0.625em;
  color: var(--black);
  font-family: inherit;
  outline: none;
}

.formInput:focus {
  box-shadow: 0px 0.125em 0.125em 0.125em rgba(204, 204, 204, 0.1);
}

.submitButton {
  background: var(--gradientText);
  width: 100%;
  padding: 0.5em 8.5em;
  border-radius: 0.25em;
  margin-top: 2.5em;
  cursor: pointer;
}

.submitButton:hover {
  box-shadow: 0.125em 0.125em 0.5em rgba(32, 32, 32, 0.25);
}

.submitButtonText {
  color: var(--white);
  margin: 0;
}

.demoFormFieldWrapper {
  position: relative;
}

.requiredField {
  color: var(--red);
  font-size: 0.875em;
  font-weight: 600;
  position: absolute;
  display: none;
  top: -8px;
  right: 0;
}

.errorData {
  color: var(--red);
  font-size: 1em;
  font-weight: 600;
  margin: 0.7em 0em;
  display: block;
}

.formSuccessIcon {
  width: 8.3125em;
}
.successTextHead {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--black);
  margin-top: 3em;
}
.formSuccessUi {
  text-align: center;
  margin-top: 3em;
}

.gradientText {
  background: var(--gradientText);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.successText {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(42, 42, 42, 0.8);
  line-height: 2em;
}

.stroke {
  position: absolute;
  width: 30.405em;
  left: 0em;
  top: 0em;
}

/* .buyButton {
  width: 13em;
} */

.dataForm {
  margin-top: 2em;
  text-align: center;
}
@media (max-width: 480px) {
  .container {
    max-width: 18.8125em;
    padding: 1.75em 1.9375em;
    margin-top: 2vh;
  }

  .heading {
    color: var(--black);
    font-weight: 800;
    margin: 0;
    font-size: 1rem;
  }

  .closeIcon {
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    width: 1.65em;
    cursor: pointer;
  }

  .formInput,
  .formSelect {
    border: 0.0625em solid #cccccc;
    border-radius: 0.25em;

    width: 100%;
    height: 2em;
    font-size: 1rem;
    padding: 0.2em 0.625em;
    color: var(--black);
    outline: none;
  }

  .submitButton {
    background: var(--gradientText);
    width: 100%;
    padding: 0.5em 1.5em;
    border-radius: 0.25em;
    margin-top: 2.5em;
    cursor: pointer;
    text-align: center;
  }

  .formSuccessIcon {
    width: 6.3125em;
  }

  .successTextHead {
    font-size: 1.3rem;
    font-weight: 800;
    color: var(--black);
    margin-top: 3em;
  }

  .successText {
    font-size: 1rem;
    font-weight: 600;
    color: rgba(42, 42, 42, 0.8);
    line-height: 2em;
  }

  .stroke {
    width: 18.405em;
  }
  .demoForm {
    gap: 0.5em;
  }
  .dataForm {
    margin-top: 1em;
    text-align: center;
  }
}
