.container {
  display: flex;
  justify-content: space-around;
  padding: 5.1875em 0 3.9375em 0;
  position: relative;
  align-items: flex-end;
}

.containerOtherPage {
  background: var(--gradientText);
  margin: 0;
  padding: 2.53em 0;
}

.containerMain {
  display: flex;
  justify-content: space-around;
  padding: 5.1875em 0 3.9375em 0;
  position: relative;
  color: var(--white);
}

.logoWrapper {
  display: flex;
  gap: 2em;
  align-items: flex-end;
}

.linksWrapper {
  display: flex;
  align-items: center;
  gap: 4.4375em;
}

.links {
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
  margin: 0;
}

.rightsText {
  font-size: 1rem;
  font-weight: 400;
  color: var(--black);
  margin: 0;
}

.logo {
  width: 13.0625em;
}

.instaLogo {
  width: 1.5em;
  cursor: pointer;
  margin-bottom: 0.4em;
}

@media (min-width: 1440px) {
  .container {
    width: 69.375em;
    margin: auto;
    justify-content: space-between;
  }

  .containerMain {
    width: 69.375em;
    margin: auto;
  }
  .logo {
    width: 13.0625em;
  }

  .instaLogo {
    width: 1.455em;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 4.845em;
  }

  .instaLogo {
    width: 0.996875em;
    margin-bottom: 0;
  }

  .logoWrapper {
    gap: 0.625em;
  }

  .linksWrapper > p {
    font-size: 0.75rem;
  }
  .rightsText {
    position: absolute;
    bottom: 0;
    font-size: 0.625rem;
  }
  .linksWrapper {
    gap: 1.625em;
  }
}
