.container {
  padding-top: 8.75em;
  position: relative;
  background-color: white;
  padding-bottom: 8.75em;
}

.heading {
  color: var(--black);
  font-weight: 600;
  font-size: 2.25rem;
  text-transform: uppercase;
  margin: 0;
}

.subHeading {
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--lightBlack);
  margin: 0;
  margin-top: 0.4em;
}

.body {
  margin-top: 5em;
  margin-left: 10.3125em;
  margin-right: 10.3125em;
  display: flex;
  justify-content: space-between;
}

.subscriptionBox {
  position: relative;
  background-color: #f9f9ff;
  border-radius: 1em;
  padding: 1em 0;
  width: fit-content;
  min-width: 15.9375em;
  cursor: pointer;
}

.subscriptionBox:hover {
  filter: drop-shadow(0.125em 0.125em 0.5em rgba(32, 32, 32, 0.08));
}

/* .subscriptionBox:hover {
  background: var(--gradientText);
}

.subscriptionBox:hover .subscriptionHeading {
  color: var(--white);
}

.subscriptionBox:hover .subscriptionFeatureText {
  color: var(--white);
}
.subscriptionBox:hover .subscriptionPrice {
  background: transparent;
  -webkit-text-fill-color: var(--white);
} */

/* .subscriptionBox:hover .subscriptionHeading {
  color: var(--white);
}

.subscriptionBox:hover .subscriptionFeatureText {
  color: var(--white);
}
.subscriptionBox:hover .subscriptionPrice {
  background: transparent;
  -webkit-text-fill-color: var(--white);
} */

.subscriptionHeading {
  font-weight: 700;
  color: var(--black);
  font-size: 1.5rem;
  text-transform: capitalize;
}

.subscriptionPrice {
  background: var(--gradientText);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 200;
  font-size: 4rem;
  margin: 0;
  margin-top: 1em;
}

.subscriptionFeatures {
  margin-top: 3.6875em;
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 0.75em;
  margin-bottom: 3.75em;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.subscriptionFeature {
  display: flex;
  gap: 0.9375em;
  width: fit-content;
  align-items: flex-start;
}

.checkMark {
  width: 0.8125em;
  height: 1.5em;
}

.subscriptionFeatureText {
  color: var(--black);
  font-weight: 300;
  margin: 0;
  font-size: 1.125rem;
}

.getStarted {
  position: absolute;
  bottom: -2em;
  left: 2.2em;
  cursor: pointer;
  background: var(--white);
  border-radius: 0.75em;
  padding: 0.9375em 2.625em;
  filter: drop-shadow(0.125em 0.125em 0.5em rgba(32, 32, 32, 0.08));
}

.getStartedActive {
  background: var(--gradientText);
  border: 1px solid var(--white);
}

.getStartedText {
  background: var(--gradientText);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

.getStartedTextActive {
  background: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--white);
}

.subscriptionBoxActive {
  background: var(--gradientText);
}

.subscriptionHeadingActive,
.subscriptionFeatureTextActive {
  color: var(--white);
}

.subscriptionPriceActive {
  background: transparent;
  -webkit-text-fill-color: var(--white);
}

@media (max-width: 768px) {
  .body {
    margin-left: 5.3125em;
    margin-right: 5.3125em;
    flex-wrap: wrap;
    gap: 5em;
    justify-content: center;
  }

  .subscriptionBox {
    min-width: 20em;
  }
  .getStarted {
    left: 3.5em;
  }
  .getStartedText {
    font-size: 1.5rem;
  }
}

@media (min-width: 1440px) {
  .heading {
    font-size: 2.25rem;
  }
  .subHeading {
    font-size: 1.25rem;
  }
  .body {
    margin: auto;
    width: 69.375em;
    margin-top: 5em;
  }
}

@media (max-width: 480px) {
  .body {
    flex-direction: row;
    margin: auto;
    margin-top: 2.5em;
    flex-wrap: nowrap;
  }
  .container {
    padding: 4.4375em 0;
  }
  .heading {
    font-size: 1.25rem;
  }
  .subHeading {
    font-size: 0.875rem;
  }
  .subscriptionBox {
    min-width: 10.625em;
    min-height: 31em;
    padding: 0.7em;
  }

  .subscriptionHeading {
    font-size: 1rem;
  }
  .subscriptionPrice {
    font-size: 2.5rem;
    margin-top: 0.5em;
  }
  .subscriptionFeatureText {
    font-size: 0.75rem;
  }
  .checkMark {
    width: 0.5625em;
    height: 1em;
  }

  .getStarted {
    padding: 0.625em 1.5em;
    width: 8.625em;
    left: 1em;
    bottom: -1em;
  }

  .getStartedText {
    font-size: 0.875rem;
  }
  .subscriptionFeatures {
    margin: 1.875em auto;
  }
}
