.containerWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background: rgba(26, 26, 26, 0.36);
  top: 0;
  left: 0;
  display: none;
}

.containerWrapperActive {
  display: block;
}

.container {
  background-color: var(--white);
  box-shadow: 0.125em 0.125em 0.5em rgba(32, 32, 32, 0.08);
  border-radius: 1em;
  max-width: 36.8125em;

  margin: auto;
  position: relative;
  text-align: left;
}

.header {
  background: var(--gradientText);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.25em;
}

.logo {
  width: 6.3125em;
}

.closeIcon {
  width: 1.375em;
}

.body {
  padding: 1.25em;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.navItem {
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0;
}

.bookButton {
  background: var(--gradientText);
  color: var(--white);
  border-radius: 0.5em;
  padding: 0.6875em 2em;
}

.buttonText {
  margin: 0;
}
