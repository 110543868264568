.container {
  background: linear-gradient(
    110.52deg,
    rgba(58, 111, 232, 0.16) -2.51%,
    rgba(156, 47, 242, 0.16) 119.43%
  );
}

.body {
  position: relative;
  display: flex;
  margin: 0 16.5em;
  justify-content: space-between;
  padding: 4.5em 0;
  align-items: center;
}

.featureImg {
  width: 4.5em;
  height: 4.0625em;
}

.featureText {
  color: var(--black);
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

.elementWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .body {
    margin: 0 5.5em;
  }
}

@media (min-width: 1440px) {
  .featureImg {
    width: 4.545625em;
  }
  .featureText {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .body {
    margin: auto;
    width: 69.375em;
  }
}

@media (max-width: 480px) {
  .body {
    flex-wrap: wrap;
    margin: 0 4.0625em;
    gap: 2.0625em;
    padding: 1.75em 0;
  }

  .featureImg {
    width: 2.625em;
    height: 2.34625em;
  }

  .featureText {
    font-size: 0.625rem;
    font-weight: 400;
  }

  .elementWrapper {
    gap: 0.8125em;
  }
}
