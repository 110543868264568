.container {
  margin-top: 7.625em;
  position: relative;
}

.containerBody {
  margin: 7.25em 0;
}

.headingText {
  font-size: 2.25rem;
  font-weight: 600;
  color: var(--black);
  text-transform: uppercase;
  margin: 0;
}

.subHeadingText {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--lightBlack);
  margin: 0;
  margin-top: 0.4em;
}

.stepWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8em;
}

.stepTextWrapper {
  display: flex;
  align-items: center;
  max-width: 34em;
  gap: 0.625em;
}

.stepTextContainer {
  text-align: left;
  position: relative;
}

.stepNumber {
  background: var(--gradientText);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 6rem;
  font-family: "open sans";
  font-weight: 300;
  margin: 0;
}

.stepHeading {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--black);
  text-transform: uppercase;
  margin: 0;
}

.stepText {
  font-size: 1.125rem;
  margin: 0;
  font-weight: 400;
  margin-top: 0.89em;
  color: var(--lightBlack);
}

.stepImage {
  width: 25.8125em;
}

.stepWrapperRev {
  flex-direction: row-reverse;
}

.bgElement {
  position: absolute;
  content: "";
  width: 100vw;
  height: 130vh;
  background: linear-gradient(180deg, #edf1ff 0%, rgba(243, 246, 255, 0) 100%);

  z-index: -2;
  top: -20em;
}

.ellipse {
  width: 1.75em;
  position: absolute;
  left: -0.5em;
  top: -0.3em;
}

@media (min-width: 768.5px) and (max-width: 1440px) {
  .container {
    margin-top: 30em;
  }
}

@media (max-width: 768px) {
  .stepImage {
    width: 20.8em;
  }

  .stepWrapper {
    gap: 2em;
  }
}

@media (min-width: 1440px) {
  .container {
    margin-top: 20em;
  }
  .headingText {
    font-size: 2.25rem;
  }
  .subHeadingText {
    font-size: 1.25rem;
  }
  .stepImage {
    width: 25.8em;
  }
  .stepHeading {
    font-size: 1.5rem;
  }
  .stepNumber {
    font-size: 6rem;
  }
  .stepText {
    font-size: 1.125rem;
  }
  .ellipse {
    width: 1.75em;
  }
}

@media (max-width: 480px) {
  .headingText {
    font-size: 1.25rem;
  }
  .subHeadingText {
    font-size: 0.875rem;
  }

  .stepWrapper {
    flex-direction: column;
  }

  .containerBody {
    margin-top: 2.9375em;
    display: flex;
    flex-direction: column;
    gap: 3.125em;
  }
  .stepImage {
    width: 12.625em;
  }

  .stepNumber {
    font-size: 3rem;
  }
  .stepHeading {
    font-size: 1rem;
  }
  .ellipse {
    width: 1.125em;
  }
  .stepText {
    font-size: 0.75rem;
  }

  .stepTextWrapper {
    margin: 0 1.25em;
  }
}
