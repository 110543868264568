html {
  font-size: 1vw;
}
body {
  margin: 0;
  font-family: "Nunito Sans";
  --white: #ffffff;
  --black: #2a2a2a;
  --lightBlack: rgba(42, 42, 42, 0.6);
  --gradientText: linear-gradient(110.52deg, #3a6fe8 -2.51%, #9c2ff2 119.43%);
  --red: #ff0000;
}

* {
  box-sizing: border-box;
}

@media (max-width: 768px) {
  html {
    font-size: 1.5vw;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 4.5vw;
  }
}

@media (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

.slick-slider {
  width: 100%;
}

.slick-slide {
  margin-left: 1.5em;
  max-width: 10.625em;
  padding-bottom: 2em;
}

.slick-list {
  width: 55em !important;
}
