.container {
  background: var(--gradientText);
  position: relative;

  align-items: center;
  min-height: 15.625em;
}

.containerImg {
  position: absolute;
  width: 23em;
  left: -18em;
}

.containerText {
  font-weight: 300;
  font-size: 2.25rem;
  color: var(--white);
  margin: 0;
}

.containerContent {
  display: flex;
  position: relative;
  margin-left: 10.3125em;
  margin-right: 10.3125em;
  align-items: center;
  justify-content: space-between;
  height: 15.625em;
}

.inputWrapper {
  width: 36.875em;
  display: flex;
  position: relative;
}

.userInput {
  background-color: var(--white);
  width: 100%;
  outline: none;
  border: none;
  font-family: inherit;
  height: 4.0625em;
  padding: 1.3125em;
  padding-right: 8em;
  font-size: 1em;
  color: var(--black);
  font-weight: 300;
  border-radius: 0.5em 0.5em;
  opacity: 1;
}

.scheduleButton {
  border-radius: 0 0.5em 0.5em 0;
  padding-top: 1.1875em;
  padding-right: 1.1875em;
  background-color: var(--white);
}

.buttonText:hover {
  background: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: black;
}

.buttonText {
  background: var(--gradientText);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  font-size: 1.25em;
  font-weight: 600;
  position: absolute;
  right: 1em;
  top: 0.9em;
  cursor: pointer;
}

.userInput::placeholder {
  font-size: 1rem;
  font-weight: 300;
  color: var(--lightBlack);
  font-family: inherit;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .inputWrapper {
    width: 30em;
  }
  .userInput {
    width: 100%;
  }
  .containerContent {
    margin-right: 5em;
  }
  .containerText {
    font-size: 1.6rem;
  }
  .scheduleButton {
    margin-left: -0.5em;
  }
  .containerImg {
    left: -8em;
    width: 23.5em;
  }
}

@media (min-width: 1440px) {
  .containerContent {
    width: 69.375em;
    margin: auto;
    position: relative;
  }

  .inputWrapper {
    width: 36.875em;
  }
  .containerImg {
    width: 23.5em;
    position: absolute;
    left: -20em;
  }

  .scheduleButton {
    border-radius: 0 0.5em 0.5em 0;
    padding: 1.1875em 1.1875em;
    margin-left: -1px;
    background-color: var(--white);
  }
}

@media (max-width: 480px) {
  .containerImg {
    display: none;
  }
  .containerText {
    font-size: 1.25rem;
  }

  .containerContent {
    margin: 0 1.25em;
    flex-direction: column;
    height: auto;
    gap: 1.25em;
  }
  .container {
    padding: 2em 0;
    min-height: 9.6875em;
  }

  .inputWrapper {
    width: 20em;
  }
  .userInput {
    width: 100%;
    height: auto;
    font-size: 0.75rem;
    padding: 0.875em 0.75em;
    padding-right: 7em;
  }

  .userInput::placeholder {
    font-size: 0.75rem;
  }
  .scheduleButton {
    padding: 0.8125em 0.75em;
    display: flex;
    align-items: center;
    width: 5.1875em;
  }

  .buttonText {
    font-size: 0.875rem;

    right: 1em;
    top: 0.6em;
  }
}
